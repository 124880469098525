import ApiV2 from '../ApiV2';

export const getProductDesignerArtifacts = async (
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.artifacts.get(payload);
  return response?.data;
};

export const uploadProductDesignerArtifact = async (
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.artifacts.post(data);
  return response?.data;
};

export const deleteProductDesignerArtifact = async (
  id: number,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.artifacts.delete(id);
  return response?.data;
};

export const getProductDesignerTemplates = async (
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.get(payload);
  return response?.data;
};

export const getProductDesignerTemplate = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.get(id);
  return response?.data;
};

export const createProductDesignerTemplate = async (
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.post(data);
  return response?.data;
};

export const updateProductDesignerTemplate = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.post(id, data);
  return response?.data;
};

export const deleteProductDesignerTemplate = async (
  id: number,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.delete(id);
  return response?.data;
};

export const createProductDesignerTemplateLayer = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.layers.post(
    // @ts-ignore
    {id},
    data,
  );
  return response?.data;
};

export const updateProductDesignerTemplateLayer = async (
  id: number,
  lid: any,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.layers.post(
    // @ts-ignore
    {id, lid},
    data,
  );
  return response?.data;
};

export const deleteProductDesignerTemplateLayer = async (
  id: number,
  lid: any,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.layers.delete(
    id,
    lid,
  );
  return response?.data;
};

export const createProductDesignerTemplateOption = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.options.post(
    id,
    data,
  );
  return response?.data;
};

export const updateProductDesignerTemplateOption = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.options.patch(
    id,
    data,
  );
  return response?.data;
};

export const deleteProductDesignerTemplateOption = async (
  id: number,
  oid: any,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.options.patch(
    id,
    oid,
  );
  return response?.data;
};

export const cloneProductDesignerTemplate = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.clone.post(
    id,
    data,
  );
  return response?.data;
};

export const assignProductDesignerTemplate = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.assign.put(
    id,
    data,
  );
  return response?.data;
};

export const unAssignProductDesignerTemplate = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.assign.delete(
    id,
    data,
  );
  return response?.data;
};

export const getProductDesignerTemplateTypes = async (filter: any) => {
  // const response = await ApiV2.op.productDesigner.templateTypes.get(filter);
  // return response?.data;
  return {
    data: [
      {
        id: 1,
        name: 'Custom',
        description: 'Template with custom size.',
        sort_number: 1,
        sizes: [
          {
            name: '100x150cm',
            description: null,
            sort_number: 1,
            layers: {
              default: {
                width: 1000,
                height: 1500,
                dpi: 96,
              },
            },
          },
        ],
        processors: [],
      },
      {
        id: 2,
        name: 'Blanket',
        description: 'Template for the blanket product.',
        sort_number: 2,
        sizes: [
          {
            name: '150x200cm',
            description: null,
            sort_number: 1,
            layers: {
              default: {
                width: 1500,
                height: 2000,
                dpi: 96,
              },
            },
          },
        ],
        processors: [
          {
            id: 1,
            name: 'SublimeMirroredPDF',
            description: 'Optimized PDF for Sublimation Printing.',
          },
        ],
      },
      {
        id: 3,
        name: 'Towel',
        description: 'Template for the towel product.',
        sort_number: 2,
        sizes: [
          {
            name: '100x150cm',
            description: null,
            sort_number: 1,
            layers: {
              default: {
                width: 1000,
                height: 1500,
                dpi: 96,
              },
            },
          },
        ],
        processors: [
          {
            id: 1,
            name: 'SublimeMirroredPDF',
            description: 'Optimized PDF for Sublimation Printing.',
          },
        ],
      },
      {
        id: 4,
        name: 'Duvet Cover',
        description: 'Template for the duvet cover product.',
        sort_number: 2,
        sizes: [
          {
            name: '140x200cm',
            description: null,
            sort_number: 1,
            layers: {
              duvet: {
                description: 'Duvet',
                width: 1400,
                height: 2000,
                dpi: 96,
                is_required: true,
              },
              pillow: {
                description: 'Pillow',
                width: 700,
                height: 500,
                dpi: 96,
                is_required: true,
              },
              'pillow-back': {
                description: 'Pillow Back',
                width: 700,
                height: 500,
                dpi: 96,
                is_required: false,
              },
            },
          },
          {
            name: '140x220cm',
            description: null,
            sort_number: 2,
            layers: {
              duvet: {
                description: 'Duvet',
                width: 1400,
                height: 2200,
                dpi: 96,
                is_required: true,
              },
              pillow: {
                description: 'Pillow',
                width: 700,
                height: 500,
                dpi: 96,
                is_required: true,
              },
              'pillow-back': {
                description: 'Pillow Back',
                width: 700,
                height: 500,
                dpi: 96,
                is_required: false,
              },
            },
          },
          {
            name: '200x200cm',
            description: null,
            sort_number: 3,
            layers: {
              duvet: {
                description: 'Duvet',
                width: 2000,
                height: 2000,
                dpi: 96,
                is_required: true,
              },
              pillow: {
                description: 'Pillow',
                width: 700,
                height: 500,
                dpi: 96,
                is_required: true,
              },
              'pillow-back': {
                description: 'Pillow Back',
                width: 700,
                height: 500,
                dpi: 96,
                is_required: false,
              },
            },
          },
          {
            name: '200x220cm',
            description: null,
            sort_number: 4,
            layers: {
              duvet: {
                description: 'Duvet',
                width: 2000,
                height: 2200,
                dpi: 96,
                is_required: true,
              },
              pillow: {
                description: 'Pillow',
                width: 700,
                height: 500,
                dpi: 96,
                is_required: true,
              },
              'pillow-back': {
                description: 'Pillow Back',
                width: 700,
                height: 500,
                dpi: 96,
                is_required: false,
              },
            },
          },
          {
            name: '240x220cm',
            description: null,
            sort_number: 4,
            layers: {
              duvet: {
                description: 'Duvet',
                width: 2400,
                height: 2200,
                dpi: 96,
                is_required: true,
              },
              pillow: {
                description: 'Pillow',
                width: 700,
                height: 500,
                dpi: 96,
                is_required: true,
              },
              'pillow-back': {
                description: 'Pillow Back',
                width: 700,
                height: 500,
                dpi: 96,
                is_required: false,
              },
            },
          },
        ],
        processors: [
          {
            id: 3,
            name: 'DuvetCoverPDF',
            description: 'Optimized PDF for Duvet Cover Printing.',
          },
        ],
      },
    ],
  };
};
