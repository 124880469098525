import {allParams} from '../constants/params';
import {getValueByUnit, unitKeys} from './unitHelper';

export const supportedDPIs = [300, 200, 150, 96];

export const getTemplateRequest = (
  settings: any,
  file: any,
  layerOrder: number,
) => {
  const properties = {
    width: settings?.width,
    height: settings.height,
  };

  const formData = new FormData();
  if (layerOrder === 1) {
    formData.append('file', file);
  }
  formData.append('name', settings?.name);
  formData.append('type', 'canvas');
  // formData.append('shop_id', shopId);
  if (layerOrder === 1) {
    formData.append('properties', JSON.stringify(properties));
  }
  // @ts-ignore
  formData.append('is_customizable', Number(settings?.isCustomizable));
  for (let i = 0; i < settings?.tags?.length; i++) {
    const tag = settings?.tags[i];
    formData.append(
      `tags[${i}][tag_id]`,
      JSON.stringify(tag?.tag_id || tag?.id),
    );
  }
  return formData;
};

export const getNewTemplateRequest = (settings: any) => {
  const properties = {
    width: settings?.width,
    height: settings.height,
  };
  const formData = new FormData();
  formData.append('name', settings?.name);
  formData.append('type', 'canvas');
  // formData.append('shop_id', shopId);
  formData.append('properties', JSON.stringify(properties));
  return formData;
};

export const getLayerRequest = (
  layer: {name: string; description: string; order: string},
  data: any,
) => {
  const dataObj = JSON.parse(data);
  const properties = {
    width: dataObj?.settings?.width,
    height: dataObj?.settings.height,
    dpi: dataObj?.settings?.dpi,
    fill_style: dataObj?.settings?.fill_style,
  };
  return {
    name: layer?.name,
    description: layer?.description,
    order: layer?.order,
    properties,
    data,
  };
  // const formData = new FormData();
  // formData.append('name', layer?.name);
  // formData.append('order', layer?.order);
  // formData.append('data', data);
  // return formData;
};

export const getTextParameters = (key: string) => {
  let parametersArray: any[] = [];
  if (allParams?.length) {
    // @ts-ignore
    parametersArray = allParams.find(
      (i: {name: string}) => i?.name === key,
    )?.values;
    //   .map((i: any) => {
    //   return {name: i, value: i};
    // });
  }

  return parametersArray;
};

export const initialTemplateSettings = () => ({
  name: `Template-${new Date().getTime()}`,
  width: 3779.52,
  height: 5669.29,
  tags: [],
  sizeUnit: 'cm',
  isCustomizable: false,
  useBackgroundImageSize: false,
});

export const getPreviewDataUrl = (
  ref: any,
  settings: {width: number; height: number},
  scale: number,
  pixelRatio: number,
) => {
  return ref.toDataURL({
    pixelRatio: pixelRatio,
    x: ref.getPosition().x * scale,
    y: ref.getPosition().y * scale,
    width: settings?.width * scale,
    height: settings?.height * scale,
  });
};

const getMaxAllowedSumInPx = () =>
  getValueByUnit(3000, unitKeys.mm, unitKeys.pixels, 96) +
  getValueByUnit(3000, unitKeys.mm, unitKeys.pixels, 96);

export const isAllowDimension = (width: number, height: number) => {
  return width + height <= getMaxAllowedSumInPx();
};

export const getFixedMaxLayerDimension = (layer: any) => {
  const maxAllowedSum = getMaxAllowedSumInPx();
  const [widthMm, heightMm] = [layer?.width, layer?.height, layer?.dpi];
  const getSizeInPixels = (widthMm: number, heightMm: number, dpi: number) => {
    const width = getValueByUnit(widthMm, unitKeys.mm, unitKeys.pixels, dpi);
    const height = getValueByUnit(heightMm, unitKeys.mm, unitKeys.pixels, dpi);
    return {width, height};
  };
  const isAllow = (widthMm: number, heightMm: number, dpi: number) => {
    const {width, height} = getSizeInPixels(widthMm, heightMm, dpi);
    return width + height <= maxAllowedSum;
  };
  const dpiCheckList = supportedDPIs.filter(i => i <= layer?.dpi);
  const resultDpi = dpiCheckList.find(dpi => isAllow(widthMm, heightMm, dpi));
  return {
    ...getSizeInPixels(widthMm, heightMm, resultDpi || 96),
    dpi: resultDpi || 96,
  };
};
