import ApiClient from 'tixxio-apiclient';
import {StorageInterface} from 'tixxio-apiclient/lib/interfaces';

const tokenStorage: StorageInterface = {
  setAccessTokens: data => localStorage.setItem('token', JSON.stringify(data)),
  clearTokens: () => localStorage.removeItem('token'),
  getAccessToken: () =>
    localStorage.getItem('token')
      ? JSON.parse(localStorage.getItem('token') || '')?.access_token
      : null,
  getRefreshToken: () =>
    localStorage.getItem('token')
      ? JSON.parse(localStorage.getItem('token') || '')?.refresh_token
      : null,
  getAccessTokenAsync: async () => {},
  getRefreshTokenAsync: async () => {},
};

const Api: ApiClient = new ApiClient(
  {
    // make sure env have separate v2 api url
    baseUrl:
      process.env.REACT_APP_DATA_API_V2_URL ||
      `${process.env.REACT_APP_DATA_API_URL}/v2`,
    headers: {
      'Client-Key': process.env.REACT_APP_API_CLIENT_KEY,
    },
    onError: (error: any): Promise<any> => {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/';
      }
      return Promise.reject(error);
    },
  },
  tokenStorage,
);
export default Api;
